<template>
  <div class="bg-white shadow rounded-4 p-3 p-lg-4 col-xl-8 col-12 mb-3">
    <div class="d-lg-flex pb-4">
      <div class="circle-avatar me-5 mb-4 mb-lg-0 pulsate"></div>
      <div>
        <div class="block1 pulsate rounded-4 mb-4"></div>
        <div class="block6 pulsate rounded-4"></div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="block2 pulsate me-3"></div>
      <div class="block2 pulsate"></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.content {
  min-height: 80px;
}
.block6 {
  width: 300px;
  height: 12px;
}
.block1 {
  width: 60%;
  height: 12px;
}
.block2 {
  width: 150px;
  height: 40px;
  border-radius: 24px;
}

.pulsate {
  background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}

.circle-avatar {
  width: 70px;
  height: 70px;
  object-fit: cover;

  @include media_mobile() {
    width: 40px;
    height: 40px;
  }
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
